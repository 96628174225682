import React from "react"
import { Link } from "gatsby"

import { IoIosBed } from "@react-icons/all-files/io/IoIosBed"
import { FaBath } from "@react-icons/all-files/fa/FaBath"
import { FaRulerCombined } from "@react-icons/all-files/fa/FaRulerCombined"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"

const ListingCard = ({ listing }) => {
  let linkedPath = 'places';
  if (listing.PropertyType === "Residential Lease") {
    linkedPath = 'rentals';
  }

  return (
    <Link
      className="post-link listing-card"
      to={listing.path}
    >
      <div className="image-holder">
        <img
          alt="property"
          src={listing.PrefferedPhoto.replace('WIDTH', 800).replace('HEIGHT', 800 / 1.5)}></img>
      </div>
      <div
        className="first-info"
      >
        <h3 className="h3-header">
          ${new Intl.NumberFormat().format(listing.ListPrice)}
        </h3>
        <div
          className="separator">
          |
        </div>
        {listing.BedroomsTotal === null ? null : (
          <div
            className="first-info-inner">
            <IoIosBed className="icon-bed" />
            {listing.BedroomsTotal}
          </div>
        )}
        {listing.BathroomsTotalInteger === null ? null : (
          <div
            className="first-info-inner">
            <FaBath className="icon-common" />
            {listing.BathroomsTotalInteger}
          </div>
        )}
        {listing.LivingArea === null ? null : (
          <div
            className="first-info-inner">
            <FaRulerCombined className="icon-common" />
            {new Intl.NumberFormat().format(listing.LivingArea)} SF
          </div>
        )}
      </div>
      <div
        className="inner-location"
      >
        <FaMapMarkerAlt className="icon-location" />{" "}
        {listing.UnparsedAddress.toUpperCase()}
      </div>
    </Link>
  )

}

export default ListingCard