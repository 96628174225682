import * as React from "react"
import parse from 'html-react-parser'
import { StaticImage } from "gatsby-plugin-image"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt"
import { FaMobileAlt } from "@react-icons/all-files/fa/FaMobileAlt"
import { FaAddressCard } from "@react-icons/all-files/fa/FaAddressCard"

const AgentBio = ({
  agentHeadshot,
  agentFirstName,
  agentLastName,
  agentFullName,
  agentTitle,
  agentPreferredPhone,
  agentOfficePhone,
  agentMobilePhone,
  agentEmail,
  agentAddress1,
  agentAddress2,
  agentCity,
  agentStateOrProvince,
  agentPostalCode,
  agentOfficeName,
  agentDescription,
}) => {
  /*This method is present on agentcard component too*/
  const getAgentVCard = () => {
    let vCardBuilder = `BEGIN:VCARD\n`
    vCardBuilder += `VERSION:2.1\n`
    vCardBuilder += `N:${agentFirstName};${agentLastName}\n`
    vCardBuilder += `FN:${agentFullName}\n`
    vCardBuilder += `ADR;HOME;PREF:;;${agentAddress1} ${agentAddress2};${agentCity};;${""}\n`
    vCardBuilder += `ORG:${agentOfficeName}\n`
    vCardBuilder += `TITLE:${""}\n`
    vCardBuilder += `TEL;HOME;VOICE:${agentOfficePhone}\n`
    vCardBuilder += `TEL;CELL;VOICE:${agentMobilePhone}\n`
    vCardBuilder += `EMAIL;PREF;INTERNET:${agentEmail}\n\n\n\n`
    vCardBuilder += `END:VCARD\n`

    let vFileName = `${agentFirstName}-${agentLastName}.VCF`

    let blob = new Blob([vCardBuilder], {
      type: "text/vcard;charset=utf-8",
    })

    let vcardURL = window.URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = vcardURL
    link.download = vFileName

    document.body.appendChild(link)

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    )

    window.URL.revokeObjectURL(vcardURL)

    document.body.removeChild(link)
  }

  const displayBorderBetween = () => {
    return (
      (agentOfficePhone || agentMobilePhone) &&
      (agentEmail || agentAddress.HasAddress)
    )
  }

  const getAgentAddress = () => {
    let agentAddress = ""
    let agentCityState = ""

    if (agentAddress1) {
      agentAddress += agentAddress1
    }

    if (agentAddress2) {
      agentAddress += agentAddress.length > 0 ? " " : ""
      agentAddress += agentAddress2
    }

    if (agentCity) {
      agentCityState += agentCity
    }

    if (agentStateOrProvince) {
      agentCityState += agentCityState.length > 0 ? ", " : ""
      agentCityState += agentStateOrProvince
    }

    if (agentPostalCode) {
      agentCityState += agentAddress.length > 0 ? " " : ""
      agentCityState += agentPostalCode
    }

    return {
      HasAddress: agentAddress.length > 0 || agentCityState.length > 0,
      Address: agentAddress,
      CityStatePostalCode: agentCityState,
    }
  }

  const getAgentOfficePhone = () => {
    let agentOfficePhone

    agentOfficePhone = agentOfficePhone
      ? agentOfficePhone
      : agentPreferredPhone
      ? agentPreferredPhone
      : null
    return agentOfficePhone
  }

  const agentAddress = getAgentAddress()
  const agentDisplayOfficePhone = getAgentOfficePhone()

  return (
    <div className="bg-mdgray bg-cover bg-bottom bg-no-repeat agent-bio-container">
      <div
        className="bio-container flex flex-col lg:flex-row flex-wrap lg:flex-nowrap justify-center bg-cover bg-no-repeat h-full w-full p-4 lg:pl-20 xl:pl-32 lg:pr-20 xl:pr-20 lg:py-16">
        <div className="containers flex flex-col lg:flex-row flex-wrap lg:flex-nowrap justify-center">
        <div className="flex flex-col justify-start  w-full lg:w-1/5 xl:w-3/12 py-2 lg:py-0 xl:py-4">
          {agentHeadshot ? (
            <img
              className="object-contain lg:object-cover lg:self-end"
              alt="agent"
              src={agentHeadshot}
            ></img>
          ) : (
            <StaticImage
              className="object-contain lg:object-cover lg:self-end"
              alt="agent"
              src="../images/no_agent_placeholder.png"
            />
          )}
        </div>
        <div className="flex flex-col justify-start self-center w-full lg:w-4/5 xl:w-9/12 py-4 lg:py-0 xl:py-6 lg:pl-5 xl:pl-7">
          <div className="flex flex-col justify-center lg:self-start w-full">
            <div className="w-full text-left lg:text-left font-bold lg:px-1">
              <h5 className="block h5-header">
                {agentFullName}
              </h5>
              <span className="block info uppercase">
                {agentTitle}
              </span>
            </div>

            <div
              className={`flex flex-col lg:flex-row flex-wrap justify-center lg:justify-start ${
                displayBorderBetween() ? " lg:divide-x lg:divide-black " : ""
              } w-full py-2`}
            >
              <div className="flex flex-row lg:flex-col flex-wrap justify-center lg:justify-start w-full lg:w-auto">
                {agentDisplayOfficePhone ? (
                  <div className="flex flex-row flex-nowrap justify-center lg:justify-start px-2 lg:pr-5 lg:pl-0 py-1 lg:py-0.5">
                    <span className="text-base lg:text-sm text-black">
                      <a href={`tel:${agentDisplayOfficePhone}`}>
                        <FaPhoneAlt />
                      </a>
                    </span>
                    <span className="pl-1 xl:py-0.5 text-sm lg:text-xs">
                      <a href={`tel:${agentDisplayOfficePhone}`}>
                        {agentDisplayOfficePhone}
                      </a>
                    </span>
                  </div>
                ) : null}

                {agentMobilePhone ? (
                  <div className="flex flex-row flex-nowrap justify-center lg:justify-start px-2 lg:pr-5 lg:pl-0 py-1 lg:py-0.5">
                    <span className="text-base lg:text-sm text-black">
                      <a href={`tel:${agentMobilePhone}`}>
                        <FaMobileAlt />
                      </a>
                    </span>
                    <span className="pl-1 xl:py-0.5 text-sm lg:text-xs">
                      <a href={`tel:${agentMobilePhone}`}>{agentMobilePhone}</a>
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col justify-center lg:justify-start w-full lg:w-auto">
                {agentEmail ? (
                  <div className="flex flex-row flex-nowrap justify-center lg:justify-start px-2 lg:pr-0 lg:pl-5 py-1 lg:py-0.5">
                    <span className="text-base lg:text-sm text-black">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          getAgentVCard()
                        }}
                        aria-hidden="true"
                      >
                        <FaAddressCard />
                      </span>
                    </span>
                    <span className="pl-1 xl:py-0.5 text-sm lg:text-xs">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          getAgentVCard()
                        }}
                        aria-hidden="true"
                      >
                        Download vCard
                      </span>
                    </span>
                  </div>
                ) : null}

                {agentAddress.HasAddress ? (
                  <div className="flex flex-row flex-nowrap justify-center lg:justify-start px-2 lg:pr-0 lg:pl-5 py-1 lg:py-0.5">
                    <span className="text-base lg:text-sm text-black">
                      <FaMapMarkerAlt />
                    </span>
                    <span className="pl-1 xl:py-0.5 text-sm lg:text-xs uppercase">
                      {agentAddress.Address.length > 0 ? (
                        <span className="block">{agentAddress.Address}</span>
                      ) : null}
                      {agentAddress.CityStatePostalCode.length > 0 ? (
                        <span className="block">
                          {agentAddress.CityStatePostalCode}
                        </span>
                      ) : null}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="lg:self-start text-justify lg:text-left text-sm lg:text-xs tracking-widest lg:tracking-widest leading-6 lg:leading-6 pt-3 lg:pt-5">
            {parse(agentDescription)}
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default AgentBio
