import * as React from "react"
import { Carousel } from "react-responsive-carousel"
import { Link } from "gatsby"

import { IoIosBed } from "@react-icons/all-files/io/IoIosBed"
import { FaBath } from "@react-icons/all-files/fa/FaBath"
import { FaRulerCombined } from "@react-icons/all-files/fa/FaRulerCombined"

import "react-responsive-carousel/lib/styles/carousel.min.css"

import leftArrow from "../images/left-arrow.png"
import rightArrow from "../images/right-arrow.png"

const AgentHeroGallery = ({ listings }) => {

  const agentListings = listings;

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 2rem)',
    width: '4rem',
    height: '4rem',
    cursor: 'pointer',
  }

  return (
    <>
      {/*TODO: For now when an agent doesm't have listings, hero section won't be displayed.*/}
      {agentListings.length > 0 ? (
        <div className="flex flex-col justify-end items-end w-full h-screen text-white p-0">
          <Carousel
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={100}
            showArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay={true}
            infiniteLoop={true}
            stopOnHover={false}
            interval={5000}
            transitionTime={1000}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <span
                  className="arrows"
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...arrowStyles, left: "1rem" }}
                  aria-hidden="true"
                >
                  <img src={leftArrow} alt=""></img>
                </span>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <span
                  className="arrows"
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...arrowStyles, right: "1rem" }}
                  aria-hidden="true"
                >
                  <img src={rightArrow} alt=""></img>
                </span>
              )
            }
          >
            {agentListings.map(item => {
              return (
                <div className="w-full h-screen relative">
                  <img
                    className="w-full h-full object-cover"
                    src={item.PrefferedPhoto.replace("WIDTH", 1800).replace(
                      "HEIGHT",
                      1800 / 1.5
                    )}
                    e
                    alt=""
                  />
                  <div
                    className="flex flex-row flex-wrap w-full h-1/4 lg:h-1/5 justify-center content-end absolute bottom-0 pb-9"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.8), rgba(0,0,0,1))`,
                    }}
                  >
                    <span className="w-full md:w-max text-4xl tracking-wider font-bold">
                      ${Intl.NumberFormat().format(item.ListPrice)}
                    </span>
                    <div className="flex flex-col justify-start w-max px-4 py-2 lg:px-4 lg:py-0">
                      <span className="uppercase tracking-widest text-sm">
                        {item.UnparsedAddress}
                      </span>
                      <div className="flex flex-row text-xs">
                        {!item.BedroomsTotal ? null : (
                          <div className="flex flex-row pr-5">
                            <IoIosBed className="mr-1" />
                            {item.BedroomsTotal}
                          </div>
                        )}
                        {!item.BathroomsTotalInteger ? null : (
                          <div className="flex flex-row pr-5">
                            <FaBath className="mr-1" />
                            {item.BathroomsTotalInteger}
                          </div>
                        )}
                        {!item.LivingArea ? null : (
                          <div className="flex flex-row pr-5">
                            <FaRulerCombined className="mr-1" />
                            {new Intl.NumberFormat().format(
                              item.LivingArea
                            )}{" "}
                            SF
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-full lg:w-max">
                      <Link className="w-max" to={item.path}>
                        <button className="py-2 px-3 text-white uppercase underline lg:no-underline text-xs font-bold cursor-pointer w-max lg:bg-mdgray">
                          View Details
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      ) : null}
    </>
  )
}

export default AgentHeroGallery