/* eslint-disable jsx-a11y/media-has-caption */

import React, {useEffect, useState} from "react"

import playIcon from "../images/play-button.png"
import pauseIcon from "../images/pause-button.png"
/* TODO: Agent video url will be pulled from the CMS. This is for presentation purposes only */
// import testVideo from "../videos/mixkit-pine-branches-in-winter-3311.mp4"

const AgentVideo = ({videoSrc}) => {

    /* TODO: Agent video url will be pulled from the CMS. This is for presentation purposes only */
    // videoSrc = testVideo

    const [playerButton, setPlayerButton] = useState(playIcon);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
       
    useEffect(() => {              
        showVideoPlayerButton(isButtonVisible);

        if(isButtonVisible){            
            setTimeout(function(){ 
                if(!isVideoPaused()){
                    setIsButtonVisible(false);                    
                }
            }, 2000);
        }
    }, [isButtonVisible])

    const onPlayerButtonClick = () => {
        
        if (isVideoPaused()) {
            playVideo();
        }
        else {
            pauseVideo();
        }
    }

    const isVideoPaused = () => {
        let videoPlayer = document.getElementById('video_player');        
        return videoPlayer.paused;
    }

    const playVideo = () => {
        let videoPlayer = document.getElementById('video_player');
        videoPlayer.play();
    }

    const pauseVideo = () => {
        let videoPlayer = document.getElementById('video_player');
        videoPlayer.pause();
    }

    const onVideoEnded = () => {
        setPlayerButton(playIcon);
        setIsButtonVisible(true);
    }

    const onVideoPause = () => {
        setPlayerButton(playIcon);
        setIsButtonVisible(true);
    }

    const onVideoPlay = () => {
        setPlayerButton(pauseIcon);
        setIsButtonVisible(false);
    }

    const onVideoMouseOver = () => {
        setIsButtonVisible(true);        
    }

    const showVideoPlayerButton = (show) => {
        let playerBtnHolder = document.getElementById('player_btn_holder');
        if(show){
            playerBtnHolder.style.visibility='visible';            
        }
        else{
            playerBtnHolder.style.visibility='hidden';            
        }
    }

    const playerBtnHolderStyle = {
        top: 'calc(50% - 3rem)',
        zIndex: 2
    }
    const playerBtnStyle = {
        width: '6rem',
        height: '6rem',
        cursor: 'pointer'
    }

    return(
        <div className="relative w-full">
            <video id="video_player" className="w-full"
                onEnded={ () => { onVideoEnded() } }
                onPause={ () => { onVideoPause() } }
                onPlay={ () => { onVideoPlay() } }
                onMouseMove={ () => { onVideoMouseOver() } }                
            >
                <source src={ videoSrc }></source>
            </video>
            <div id="player_btn_holder" className="absolute flex flex-row justify-center w-full"
                style={ playerBtnHolderStyle }
            >
                <span onClick={ ()=>{ onPlayerButtonClick() } } aria-hidden="true">
                    <img 
                        src={ playerButton }
                        style={ playerBtnStyle }
                        alt=""
                    >
                    </img>
                </span>                
            </div>
        </div>
    )
}

export default AgentVideo