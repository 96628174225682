import React, {useEffect, useState} from "react"
import ListingCard from "../components/listing-card"

import "./agent-listings.css"

const AgentListings = ({ listings }) => {
    
    const agentListings = listings;

    const showClosedOnInit = (agentListings.active.length === 0 && agentListings.closed.length > 0);
    
    const [showClosed, setShowClosed] = useState(showClosedOnInit);
    const [listingsCards, setListingsCards] = useState(showClosedOnInit ? agentListings.closed : agentListings.active);
    
    useEffect(() => {

      if(showClosed){            
        setListingsCards(agentListings.closed);            
      }
      else{                     
        setListingsCards(agentListings.active);
      }
            
    }, [showClosed, agentListings.active, agentListings.closed]);
    
    function changeView(value){
        if(value !== showClosed){
          setListingsCards([]);     
        }
        setShowClosed(value);
    }
       
    return(
        <div className='flex flex-wrap justify-evenly py-5 md:py-6 lg:py-7 xl:py-9 containers agent-listing-container'>
          <div className="w-full text-center font-extralight lg:px-1">
            <h1 className="block h1-header">
              Listings
            </h1>
          </div>
            <div className="flex flex-row flex-nowrap justify-center self-center text-lg w-full pt-3 listing-options">
              {(agentListings.active.length > 0) ?
                (<div className={`flex flex-col ${agentListings.closed.length === 0 ? "w-full" : "mx-4 w-1/2"}`}>
                  <div className={`${agentListings.closed.length === 0 ? "self-center" : "self-end"} px-1 uppercase listings-tab ${!showClosed ? "active" : ""}`}>
                    <span className="cursor-pointer" onClick={ () => { changeView(false) }} aria-hidden="true">Active</span>
                  </div>
                </div>) : null
              }
              {
                (agentListings.closed.length > 0) ?
                (<div className={`flex flex-col ${agentListings.active.length === 0 ? "w-full" : "mx-4 w-1/2"}`}>
                  <div className={`${agentListings.active.length === 0 ? "self-center" : "self-start"} px-1 uppercase listings-tab ${showClosed ? "active" : ""}`}>
                    <span className="cursor-pointer" onClick={ () => { changeView(true) }} aria-hidden="true">Sold</span>
                  </div>
                </div>) : null
              }                            
            </div>
          
            {
              listingsCards.map((item) => { return <><ListingCard listing={ item }></ListingCard></> })
            }                  

        </div>
    )
}

export default AgentListings