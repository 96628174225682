import * as React from "react"
import { Carousel } from "react-responsive-carousel"
import parse from 'html-react-parser'

import "react-responsive-carousel/lib/styles/carousel.min.css"

const AgentTestimonials = ({ agentName, testimonials }) => {
  const indicatorStyles = {
    width: 8,
    height: 8,
    borderRadius: "50%",
    display: "inline-block",
    margin: "0 8px",
    cursor: "pointer",
  }

  return (
    <div
      className="flex flex-col lg:flex-row justify-center items-center w-full text-white bg-dkgray lg:px-12 pt-5"
      style={{
        minHeight: "75vh",
      }}
    >
      <div className="containers flex flex-col lg:flex-row justify-center items-center w-full text-white bg-dkgray">
        <div className="flex flex-col items-center lg:items-end h-full w-full lg:w-2/5 lg:pr-24">
          <div className="w-max">
            <span
              className="block uppercase font-bold text-xl"
              style={{
                letterSpacing: "0.27em",
              }}
            >
              Working with
            </span>
            <div className="font-light text-5xl text-center lg:text-left">
              {agentName}
            </div>
          </div>
        </div>

        <div className="flex flex-col h-full w-full lg:w-3/5 lg:pl-24 lg:border-l lg:border-white agent-testimonial">
          <Carousel
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={100}
            showArrows={false}
            showStatus={false}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    className="bg-mdgray"
                    style={{ ...indicatorStyles, opacity: "0.6" }}
                    value={index}
                    key={index}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    aria-hidden="true"
                  />
                )
              }
              return (
                <li
                  className="bg-mdgray"
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  aria-label={`${label} ${index + 1}`}
                  aria-hidden="true"
                />
              )
            }}
          >
            {testimonials.map((item, index) => {
              return (
                <div
                  className="testimonial flex flex-row flex-nowrap w-full py-20 px-3"
                  key={`testimonial${index}`}
                >
                  <div
                    className="self-start font-bold text-7xl"
                    style={{
                      fontFamily: `Freight`,
                    }}
                  >
                    &ldquo;
                  </div>
                  <div className="text-xl font-light tracking-wider leading-9 py-2 px-2 text-left">
                    {parse(item)}
                  </div>
                  <div
                    className="self-end font-bold text-7xl"
                    style={{
                      fontFamily: `Freight`,
                    }}
                  >
                    &bdquo;
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default AgentTestimonials
