import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AgentHeroGallery from "../components/agent-hero-gallery"
import AgentBio from "../components/agent-bio"
import AgentContactForm from "../components/agent-contact-form"
import AgentListings from "../components/agent-listings"
import AgentVideo from "../components/agent-video"
import AgentTestimonials from "../components/agent-testimonials"

const Agent = ({ data, location }) => {
  const listings = {
    active: [],
    closed: [],
  }

  const residentialListings = [];
  residentialListings.push(...data.mainAgentListings.edges);
  residentialListings.push(...data.mainAgentBuyingListings.edges);
  residentialListings.push(...data.coAgentBuyingListings.edges);
  residentialListings.push(...data.coAgentListings.edges);

  const rentalListings = [];
  rentalListings.push(...data.mainAgentRentals.edges);
  rentalListings.push(...data.coAgentRentals.edges);

  const agent = data.aspenSnowmassAgent

  let testimonials = [];

  if (agent) {
    testimonials = [
      agent.testimonialOne,
      agent.testimonialTwo,
      agent.testimonialThree,
      agent.testimonialFour,
      agent.testimonialFive,
    ].filter(t => t);
  }

  const orderActiveVsClosed = (item) => {
    if (item.node.StandardStatus === "Closed") {
      listings.closed.push(item.node)
    } else {
      listings.active.push(item.node)
    }
  }

  const sortPrice = (a, b) => {
    return b.ListPrice - a.ListPrice;
  }

  residentialListings.forEach(orderActiveVsClosed)
  rentalListings.forEach(orderActiveVsClosed)
  listings.active.sort(sortPrice);
  listings.closed.sort(sortPrice);

  const agentFullName = agent ?
    (agent.firstName && agent.lastName
      ? `${agent.firstName} ${agent.lastName}`
      : agent.MemberFirstName)
    : "Agent"

  return (
    <Layout>
      {agent && (
        <>
          <Seo title={agentFullName} location={location} />
          <AgentHeroGallery listings={listings.active}></AgentHeroGallery>
          <AgentBio
            agentHeadshot={
              agent.photo
                ? process.env.MEDIA_CACHE_URL_PREPEND.replace('WIDTH', 420).replace('HEIGHT', 420 / 0.75) + agent.photo.url
                : agent.Media && JSON.parse(agent.Media).length > 0
                  ? process.env.MEDIA_CACHE_URL_PREPEND.replace('WIDTH', 420).replace('HEIGHT', 420 / 0.75) + JSON.parse(agent.Media)[0].Uri
                  : null
            }
            agentFirstName={
              agent.firstName && agent.lastName
                ? `${agent.firstName} ${agent.lastName}`
                : agent.MemberFirstName
            }
            agentLastName={agent.lastName || agent.MemberLastName}
            agentFullName={agentFullName}
            agentTitle={agent.title}
            agentPreferredPhone={agent.MemberPreferredPhone}
            agentOfficePhone={agent.MemberOfficePhone}
            agentMobilePhone={agent.MemberMobilePhone}
            agentEmail={agent.MemberEmail}
            agentAddress1={agent.MemberAddress1}
            agentAddress2={agent.MemberAddress2}
            agentCity={agent.MemberCity}
            agentStateOrProvince={agent.MemberStateOrProvince}
            agentPostalCode={agent.MemberPostalCode}
            agentOfficeName={agent.OficeName}
            agentDescription={agent.agentBio || ""}
          ></AgentBio>

          <AgentContactForm agentId={agent.mlsId}></AgentContactForm>

          {listings.active.length > 0 || listings.closed.length > 0 ? (
            <AgentListings listings={listings}></AgentListings>
          ) : null}

          {
            agent.agentVideo && agent.agentVideo.url ?
              <AgentVideo videoSrc={agent.agentVideo.url}></AgentVideo>
              :
              ''
          }

          {testimonials.length ? (
            <AgentTestimonials
              agentName={agent.MemberFirstName}
              testimonials={testimonials}
            ></AgentTestimonials>
          ) : null}
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($memberMLS: String!, $mlsId: String!, $extraMlsId: String!) {
    mainAgentListings: allListingResidential(
      filter: { ListAgentMlsId: { in: [$memberMLS, $extraMlsId] } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    mainAgentRentals: allListingRental(
      filter: { ListAgentMlsId: { in: [$memberMLS, $extraMlsId] } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    mainAgentBuyingListings: allListingResidential(
      filter: { BuyerAgentMlsId: { in: [$memberMLS, $extraMlsId] } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    coAgentBuyingListings: allListingResidential(
      filter: { CoBuyerAgentMlsId: { in: [$memberMLS, $extraMlsId] } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    coAgentListings: allListingResidential(
      filter: { CoListAgentMlsId: { in: [$memberMLS, $extraMlsId] } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    coAgentRentals: allListingRental(
      filter: { CoListAgentMlsId: { in: [$memberMLS, $extraMlsId] } }
      sort: { fields: ListPrice, order: DESC }
    ) {
      edges {
        node {
          UnparsedAddress
          ListingId
          PrefferedPhoto
          ListPrice
          LivingArea
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          parsedAddress
          StandardStatus
          PropertyType
          path
        }
      }
    }
    aspenSnowmassAgent(mlsId: { eq: $mlsId }) {
      MemberKey
      MemberFirstName
      MemberLastName
      MemberFullName
      MemberPreferredPhone
      MemberOfficePhone
      MemberMobilePhone
      MemberEmail
      Media
      MemberCity
      MemberStateOrProvince
      MemberPostalCode
      MemberAddress1
      MemberAddress2
      OfficeName
      firstName
      lastName
      mlsId
      photo {
        url
      }
      agentVideo {
        url
      }
      title
      agentBio
      testimonialOne
      testimonialTwo
      testimonialThree
      testimonialFour
      testimonialFive
    }
  }
`

export default Agent
